import React from "react"

const GiftSyorui = () => {
  const title = `贈与登記手続`
  const content = `不動産の贈与を受けると、贈与による名義変更のために所有権移転登記をする必要があります。
  贈与を原因とする所有権移転登記には、登記申請の際に固定資産税評価額の１０００分の２０（２％）の登録免許税を納付する必要があります。
  ご注意いただきたいのは、固定資産税課税標準額ではなく、評価額をご確認ください。
 
  具体例をあげると、土地・建物の合計の評価額が２,０００万円の場合、４０万円の登録免許税が課税されます。
 
  登記申請に必要な書類は下記の通りとなります。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="detail__content__list">
          <dl>
            <dt>１．贈与証書等、贈与があったことが分かる書類</dt>
            <dd>
              贈与による所有権移転登記には登記原因証明情報として、贈与の事実が分かる書類が必要です。
              <br />
              司法書士に依頼された場合、一般的には司法書士がお客様のご意向をお伺いして書類作成を行います。
            </dd>
            <dt>２．権利証（登記識別情報通知）</dt>
            <dd>
              不動産を贈る所有者の権利証（登記識別情報）が必要となります。持分を贈与する場合、今後権利証（登記識別情報）が複数になります。
            </dd>
            <dt>３．贈与者の印鑑証明書</dt>
            <dd>
              登記申請日を基準として発行後３ヶ月以内の不動産を贈る所有者の印鑑証明書が必要となります。
              <br />
              また、登記されている住所・氏名と現在の住所・氏名に変更がある場合は、前提として、その変更登記を併せてする必要があります。
            </dd>
            <dt>４．受贈者の住民票</dt>
            <dd>
              不動産を譲り受ける方の現在の住民票が必要となります。有効期限は特にありません。
            </dd>
            <dt>５．固定資産税の評価証明書（納税通知書）</dt>
            <dd>
              登録免許税の納付額の根拠として、登記申請年度の固定資産税の評価額の分かるものが必要となります。
            </dd>
            <dt>６．委任状</dt>
            <dd>
              司法書士に委任する場合、贈与者、受贈者双方の委任状が必要となります。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default GiftSyorui
