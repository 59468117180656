import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const GiftTax = () => {
  const title = `贈与税の特例のご紹介`
  const content = `国税庁のサイトに贈与税、贈与税の特例について詳しく記載されておりますので、ご紹介します。
    税金についの個別具体的なご相談は税務署、もしくは税理士にお願いいたします。
`

  return (
    <div className="detail">
      <div className="detail__content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div className="gift__tax">
          <h3>国税庁のサイト</h3>
          <blockquote cite="https://www.nta.go.jp/taxes/shiraberu/taxanswer/zoyo/zouyo.htm">
            <ul>
              <li>
                <a
                  href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/zoyo/zouyo301.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  贈与と税金
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/zoyo/4452.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  夫婦間の居住用不動産の贈与
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/sozoku/4103.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  相続時精算課税
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </li>
            </ul>
          </blockquote>
        </div>
      </div>
    </div>
  )
}

export default GiftTax
